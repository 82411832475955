export default {
    init: () => {
        const inputsDOM = document.querySelectorAll('.input');
        console.log('init')

        if (inputsDOM) {
            inputsDOM.forEach(inputDOM => {
                const inputEl = inputDOM.querySelector<HTMLInputElement>('.input__field');

                if (!inputEl) return;

                inputEl.addEventListener('focus', () => {
                    if(inputEl.nodeName !== 'SELECT')
                        inputDOM.classList.add('input--active');
                });

                inputEl.addEventListener('blur', () => {
                    if (inputEl.value === '') {
                        inputDOM.classList.remove('input--active');
                    }

                    if (inputEl.value !== '') {
                        inputDOM.classList.add('input--active');
                    }
                });

                inputEl.addEventListener('input', () => {
                    if (inputEl.classList.contains('error')) {
                        inputEl.classList.remove('error');
                    }
                    const errorMessage = inputDOM.querySelector('.error');
                    if (errorMessage) {
                        inputDOM.removeChild(errorMessage);
                    }

                    if (inputEl.value !== '') {
                        inputDOM.classList.add('input--active');
                    }
                });

                inputEl.addEventListener('change', () => {
                    if (inputEl.value !== '') {
                        inputDOM.classList.add('input--active');
                    }
                });

                if (inputEl.value !== '') {
                    inputDOM.classList.add('input--active');
                }
            });
        }

        const checkboxesDOM = document.querySelectorAll('.checkbox');

        if (checkboxesDOM) {
            checkboxesDOM.forEach(checkboxDOM => {
                const checkboxEl = checkboxDOM.querySelector<HTMLInputElement>('.checkbox__field');
                const checkboxLabel = checkboxDOM.querySelector('.checkbox__label');
                if (!checkboxEl || !checkboxLabel) return;

                const attrId = checkboxLabel.getAttribute('for') || '';
                checkboxEl.setAttribute('id', attrId);

                const { classList } = checkboxDOM;
                const checkboxChange = () => {
                    checkboxEl.checked ? classList.add('checkbox--checked') : classList.remove('checkbox--checked');
                };

                checkboxEl.addEventListener('change', checkboxChange);
                checkboxChange();
            });
        }


        const formSucesso = document.querySelector('.form__sucesso');

        if (formSucesso) {
            const button = formSucesso.querySelector('.button');
            if (button) {
                button.addEventListener('click', e => {
                    e.preventDefault();
                    formSucesso.classList.add('form__sucesso--hide');
                });
            }
        }
    }
}
