import Siema from 'siema';


export default function run() {
    const container = document.querySelector('.agenda__list');
    const pagination = document.querySelector('.agenda__pagination');
    if (!container) {
        return;
    }

    const slider = new Siema({
        selector: '.agenda__list',
        perPage: {
            640: 1,
            768: 2,
        },
    });

    const paginationToggler = () => {
        if(slider.innerElements.length <= slider.perPage) {
            pagination.classList.add('pagination--hidden');
        } else {
            pagination.classList.remove('pagination--hidden');
        }
    }

    paginationToggler();
    window.addEventListener('resize', paginationToggler);

    const prevButton = pagination.querySelector('.pagination__prev');
    const nextButton = pagination.querySelector('.pagination__next');

    prevButton?.addEventListener('click', evt => {
        evt.preventDefault();
        slider.prev();
    });

    nextButton?.addEventListener('click', evt => {
        evt.preventDefault();
        slider.next();
    });
}
