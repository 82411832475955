export default function run() {
    const imprensaTitle: HTMLElement | null = document.querySelector(".imprensa__title");

    if (!imprensaTitle) return;

    window.addEventListener("scroll", () => {
        const minTopPosition = 20;
        const distanceFromTop = imprensaTitle.offsetTop + imprensaTitle.offsetHeight;
        const velocity = 15;

        imprensaTitle.style.backgroundPosition = "left 50% top " + (minTopPosition + (window.scrollY / distanceFromTop) * velocity) + "%";
    });
}
