import Vue from 'vue';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { validationMessage } from "vuelidate-messages";
import { showItems } from './galeria';

const leadsForms = document.querySelectorAll('#leads__popup');

if(leadsForms.length > 0) {
    Vue.use(Vuelidate);

    const formMessages = {
        required: () => "O campo é obrigatório",
        email: () => "O e-mail inserido é inválido"
    };

    leadsForms.forEach(leadsForm => {
        new Vue({
            el: leadsForm,
            data: {
                loading: false,
                done: false,
                email: '',
                nome: '',
                cidade: '',
            },
            validations: {
                email: {
                    required,
                    email
                },
                nome: {
                    required
                },
                cidade: {
                    required
                },
            },
            methods: {
                validationMsg: validationMessage(formMessages, {}),
                reset() {
                    this.email = "";
                    this.nome = "";
                    this.cidade = "";
                    this.$v.$reset();
                },
                async submitHandler() {
                    this.$v.$touch();

                    if (this.$v.$invalid) return;

                    try {
                        this.loading = true;
                        await axios.post('/api/leads', {
                            email: this.email,
                            nome: this.nome,
                            cidade: this.cidade,
                            interesse: window.location.href,
                        });

                        this.reset();
                        this.done = true;

                        const popup = document.querySelector<HTMLDivElement>('.popup');
                        popup.classList.add('popup--success');
                        setTimeout(() => showItems(), 2000);
                        setTimeout(() => (popup.remove()), 10_000);
                        // setTimeout(() => (this.done = false), 4000);
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.loading = false;
                    }
                }
            }
        });
    });
}
