import SimpleParallax from "simple-parallax-js";

const sobreImg = document.querySelectorAll('.sobre__fotos .sobre__img');
const sobreBg = document.querySelector('.sobre__bg');
const scales = [
    1.5,
    3,
    2
]

sobreImg.forEach((img, i) => {
    new SimpleParallax(img, {
        orientation: 'down',
        delay: 0.5,
        scale: scales[i],
        overflow: true
    } as any);
});