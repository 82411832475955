import Cookies from 'js-cookie';

const cookies = document.querySelector('.cookies');
const cookiesButton = document.querySelector('.cookies__button');
const popupClose = document.querySelector<HTMLDivElement>('.popup__close');


cookiesButton?.addEventListener('click', () => {
    Cookies.set('politica_privacidade', 'true');
    cookies?.remove();
});

popupClose?.addEventListener('click', () => {
    Cookies.set('leads', 'none');
})