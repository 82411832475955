import './header';
import './nav';
import './popup';
import banner from './banner';
import agenda from './agenda';
import historico from './historico';
import './leads';
import './contato';
import './faclubes';
import './redes';
import './loading';
import './sobre';
import input from './input';
import './fancybox';
import './galeria';
import AnosGaleria from './AnosGaleria';
import './cookies';
import imprensa from './imprensa';
import parallax from './parallax';

banner();
agenda();
historico();
imprensa();
parallax();
AnosGaleria();

input.init();

// import './galeria';
// import './newsletter';
// import './deunamidia';
// import './contato';
// import './categorias';
// import './destaque';
// import './images';
// import 'ken-burns-carousel';
