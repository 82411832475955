import Splide, { Options } from '@splidejs/splide';

export class CarrosselConstructor {
    static fromSplide(target: string): Splide {
        let options: Options = {
            perPage: 5,
            gap: 40,
            arrows: false,
            pagination: false,
            padding: {
                right: '10%'
            },
            breakpoints: {
                640: {
                    perPage: 2,
                    padding: {
                        right: '30%'
                    }
                },
                768: {
                    perPage: 2,
                    padding: {
                        right: '20%'
                    }
                },
                1024: {
                    perPage: 4,
                    padding: {
                        right: '20%'
                    }
                },
                1279: {
                    perPage: 4,
                    gap: 60,
                    padding: {
                        right: '10%'
                    }
                },
                1681: {
                    perPage: 3,
                    padding: {
                        right: '20%'
                    }
                },
                2000: {
                    perPage: 4,
                    gap: 40,
                    padding: {
                        right: '10%'
                    },
                },
            },
        };
        let splide = new Splide(target, options);
        return splide;
    }
}