import SimpleParallax from "simple-parallax-js";

const image = document.querySelector('.redes__bg');

if (image)
    new SimpleParallax(image, {
        orientation: 'right',
        delay: 0.8,
        scale: 1.5,
        overflow: true
    });