import simpleParallax from 'simple-parallax-js';

const image = document.querySelector('.faclubes__img');

if (image)
    new simpleParallax(image, {
        delay: 0.5,
        scale: 1.5,
        maxTransition: 99,
        customWrapper: '.faclubes__media'
    });

const text = document.querySelector('.faclubes__title');

if (text)
    new simpleParallax(text, {
        delay: 0.8,
        scale: 2,
        overflow: true
    });