import Splide from '@splidejs/splide';

export default function run() {
    const container = document.querySelector('.news__container');

    if (!container) return;

    new Splide('.news__container', {
        perPage: 6,
        gap: 40,
        pagination: false,
        padding: {
            left: '7.5%',
            right: '7.5%'
        },
        classes: {
            arrows: 'splide__arrows news__pagination pagination',
            arrow: 'splide__arrow pagination__nav',
            prev: 'splide__arrow--prev pagination__prev',
            next: 'splide__arrow--next pagination__next',
        },
        breakpoints: {
            480: {
                perPage: 1,
                padding: {
                    left: '7.5%',
                    right: '30vw'
                }
            },
            640: {
                perPage: 1,
                gap: 50,
                padding: {
                    left: '7.5%',
                    right: '40vw'
                }
            },
            768: {
                perPage: 2,
                gap: 60,
                padding: {
                    left: '7.5%',
                    right: '20vw'
                }
            },
            1024: {
                perPage: 3,
                gap: 40
            },
            1366: {
                perPage: 4
            },
            1681: {
                perPage: 5
            },
        }
    }).mount();
}