import Vue from 'vue';

const el = document.querySelector('.loading');

if (el)
    new Vue({
        el,
        data: {
            duration: 1200, // milliseconds
            totalFrames: 28,
            max: 100,
            displayNumber: 0,
            interval: false
        },
        async mounted() {
            clearInterval(this.interval);

            if (this.max == this.displayNumber) {
                return;
            }

            await new Promise(r => setTimeout(r, 500));

            this.interval = window.setInterval(() => {
                if (this.displayNumber != this.max) {
                    let change = (this.max - this.displayNumber) / 10;
                    change = change >= 0 ? Math.ceil(change) : Math.floor(change);
                    this.displayNumber = this.displayNumber + change;
                }
            }, this.duration / this.totalFrames);
        },
        watch: {
            displayNumber: function (value) {
                if (value < 98) return;

                const navList = document.querySelector('.nav__list');
                const bannerTitle = document.querySelector('.banner__title');
                const wrap = document.querySelector('.news__list');
                const removeTransition = () => {
                    wrap.classList.remove('news__list--active');
                    wrap.removeEventListener('transitionend', this);
                }

                wrap.classList.remove('news__list--enter-from');

                wrap.addEventListener('transitionend', removeTransition);

                setTimeout(() => bannerTitle.classList.remove('banner__title--hidden'), 800);
                setTimeout(() => navList.classList.add('nav__list--show'), 1200);
            }
        }
    })