import Trigger from '@maximize/trigger-target-class';

new Trigger({
    triggerSelectors: ['nav'],
    classNameTarget: 'nav--open'
});


const nav = document.querySelector<HTMLElement>('.nav');

const screenHeightPercentage = 50; //%

if (nav != null) {
    function navMainInteract() {
    const factor = screenHeightPercentage / 100
        const isAfterScreen = window.scrollY >= (window.innerHeight * factor);

        toggleClassesIf(isAfterScreen, nav, 'nav--fixed');
    }

    navMainInteract();

    window.addEventListener('scroll', navMainInteract);

    function toggleClassesIf(condition: boolean, el: HTMLElement | null, className: string) {
        if (condition) {
            el.classList.add(className);
        } else {
            el.classList.remove(className);
        }
    }
}
