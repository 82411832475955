const items: NodeListOf<HTMLImageElement> = document.querySelectorAll('.galeria__img');

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const showItems = async () => {
  for (const item of items) {
    await sleep(200);

    item.closest('.galeria__item')
      .classList
      .add('galeria__item--show');

    await sleep(100);
  }
}

const modalClose = document.querySelector('.popup__close');

if (modalClose) {
  modalClose.addEventListener('click', () => showItems());
} else {
  showItems();
}
