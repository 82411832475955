const header = document.querySelector(".header");

if (header) {
    let previousScrollY = 0;
    window.addEventListener("scroll", () => {
        if (window.scrollY < previousScrollY) {
            header.classList.remove("header--fixed");
            return;
        }
        
        if (window.scrollY > 0) {
            header.classList.add("header--fixed");
        } else {
            header.classList.remove("header--fixed");
        }
        previousScrollY = window.scrollY;
    });
}
