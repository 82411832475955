import SimpleParallax from "simple-parallax-js";

export default function run() {
    const banner: HTMLElement | null = document.querySelector(".banner--fixed");
    const bannerTitle: HTMLElement | null = document.querySelector(".banner__title");
    const content: HTMLElement | null = document.querySelector(".content--fixed");

    if (!banner || !content) return;

    window.addEventListener("scroll", () => {
        const y = content.getBoundingClientRect().y;

        if (y > 0)
            banner.style.transform = "translate3d(0, -" + ((banner.offsetHeight - y) / 5) + "px ,0)";
    });

    new SimpleParallax(bannerTitle, {
        overflow: true,
        scale: 1.5,
        delay: 0
    })
}
