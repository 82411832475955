import Vue from "vue";
import Splide from '@splidejs/splide';
import { CarrosselConstructor } from "./CarrosselConstructor";

type GaleriaHeaderData = {
    years: Array<HTMLAnchorElement>,
    currentYearIndex: number,
    splide: Splide | null,
};
type GaleriaHeaderMethods = {
    next(): void;
    previous(): void;
};
type GaleriaHeaderComputed = object;
type GaleriaHeaderPropNames = never;

export default function run(): void {
    let galeriaHeader: Element | null = document.querySelector(
        /* selectors */ '.galeria__header'
    );
    if (galeriaHeader === null) {
        console.error( /* message */ 'Não foi possível encontrar um elemento de classe: .galeria__header');
        return;
    }

    let galeriaAnoAtual: HTMLAnchorElement | null = document.querySelector(
        /* selectors */ '.galeria__link--active'
    );
    if (galeriaAnoAtual === null) {
        console.error( /* message */ 'Não foi possível encontrar um elemento de classe: .galeria__link--active');
        return;
    }

    let galeriaAnoList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
        /* selectors */ '.galeria__link'
    );

    new Vue<GaleriaHeaderData, GaleriaHeaderMethods, GaleriaHeaderComputed, GaleriaHeaderPropNames>( /* options */ {
        el: galeriaHeader,
        data: {
            years: [],
            currentYearIndex: -1,
            splide: null,
        },
        mounted() {
            this.years = Array.from( 
                /* iterable */ galeriaAnoList
            );
            this.currentYearIndex = this.years.findIndex(
                /* callback */ (year: HTMLAnchorElement): boolean => year.id === galeriaAnoAtual.id
            );
            this.splide = CarrosselConstructor.fromSplide(
                /* target */ '.galeria__nav-wrap'
            );
            this.splide.mount();
            this.splide.go(
                /* index */ this.currentYearIndex
            );
        },
        methods: {
            next(): void {
                let nextYear: HTMLAnchorElement = this.years[this.currentYearIndex + 1];
                window.location.href = nextYear.href;
            },
            previous(): void {
                let previousYear: HTMLAnchorElement = this.years[this.currentYearIndex - 1];
                window.location.href = previousYear.href;
            },
        },
    });
}
