import Vue from "vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import { validationMessage } from "vuelidate-messages";
import {
    required,
    minLength,
    email,
    maxLength
} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import simpleParallax from 'simple-parallax-js';

const contatoForm = document.querySelector(".contato__form");

if (contatoForm) {
    Vue.use(Vuelidate);
    Vue.use(VueMask);

    const image = document.querySelector('.contato__img');
    new simpleParallax(image, {
        delay: 0.8,
        scale: 1.2,
        customWrapper: '.contato__media'
    });

    const media = document.querySelector('.contato__media');
    const list = document.querySelector('.contato__list');
    setTimeout(() => {
        media.classList.add('contato__media--show');
        list.classList.add('contato__list--show');
    }, 500);

    const formMessages = {
        required: () => "O campo é obrigatório",
        minLength: ({ $params }: any) =>
            `O campo deve possuir no mínimo ${$params.minLength.min} caracteres.`,
        maxLength: ({ $params }: any) =>
            `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => "O e-mail inserido é inválido"
    };

    new Vue({
        el: ".contato__form",
        data: {
            done: false,
            loading: false,
            telefoneMask: "(##) ####-#####",
            nome: "",
            email: "",
            telefone: "",
            empresa: "",
            assunto_id: "",
            mensagem: "",
            cidade: "",
            uf: "",
        },
        watch: {
            telefone(value) {
                this.telefoneMask =
                    value.length > 14 ? "(##) #####-####" : "(##) ####-#####";
            }
        },
        validations: {
            nome: {
                required,
                maxLength: maxLength(255)
            },
            email: {
                required,
                email,
                maxLength: maxLength(100)
            },
            telefone: {
                required,
                minLength: minLength(14),
                maxLength: maxLength(15)
            },
            assunto_id: {
                required,
                maxLength: maxLength(255)
            },
            mensagem: {
                required
            },
            empresa: {
                required
            },
            cidade: {
                required
            },
            uf: {
                required
            },
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                this.nome = "";
                this.email = "";
                this.telefone = "";
                this.assunto_id = "";
                this.mensagem = "";
                this.empresa = "";
                this.cidade = "";
                this.uf = "";

                this.$v.$reset();
            },
            async handleSubmit() {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    this.loading = true;

                    try {
                        await axios.post("/api/contatos", {
                            nome: this.nome,
                            email: this.email,
                            telefone: this.telefone,
                            assunto_id: this.assunto_id,
                            mensagem: this.mensagem,
                            empresa: this.empresa,
                            cidade: this.cidade,
                            uf: this.uf
                        });

                        this.reset();
                        this.done = true;

                        document.querySelectorAll('.input').forEach((elem) => { elem.classList.remove('input--active') });

                        Swal.fire(
                            "Sucesso!",
                            "O seu contato foi enviado.",
                            "success"
                        );

                        setTimeout(() => (this.done = false), 4000);
                    } catch (error) {
                        console.log(error);
                        Swal.fire(
                            "Ops! Ocorreu um problema",
                            "O envio falhou, tente novamente mais tarde",
                            "error"
                        );
                    } finally {
                        this.loading = false;
                    }
                }
            }
        }
    });
}
