import Vue from "vue";
import Splide from '@splidejs/splide';

export default function run() {
    const container = document.querySelector('.sao-joao__historico');

    if (!container) {
        return;
    }

    new Vue({
        el: '.sao-joao__historico',
        data: {
            rawPage: 0,
            max: 0,
            slide: null
        },
        computed: {
            page() {
                return this.rawPage % this.max;
            }
        },
        methods: {
            next() {
                this.rawPage += 1;

                if(this.rawPage >= this.slide.options.perPage - 1)
                    this.slide.go(this.rawPage);
            },
            prev() {
                this.rawPage -= 1;
                this.slide.go(this.rawPage);
            },
            goTo(index: number) {
                this.rawPage = index;
            }
        },
        mounted() {
            const pages = Array.from(document.querySelectorAll('.sao-joao__ano'));
            this.max = pages.length;

            this.slide = new Splide('.sao-joao__nav-wrap', {
                perPage: 5,
                gap: 40,
                arrows: false,
                pagination: false,
                padding: {
                    right: '10%'
                },
                breakpoints: {                    
                    640: {
                        perPage: 2,
                        padding: {
                            right: '30%'
                        }
                    },
                    768: {
                        perPage: 3,
                        padding: {
                            right: '20%'
                        }
                    },
                    1024: {
                        perPage: 4,
                        padding: {
                            right: '20%'
                        }
                    },
                    1279: {
                        perPage: 5,
                        gap: 60,
                        padding: {
                            right: '10%'
                        }
                    },
                    1681: {
                        perPage: 3,
                        padding: {
                            right: '20%'
                        }
                    },
                    2000: {
                        perPage: 4,
                        gap: 40,
                        padding: {
                            right: '10%'
                        },
                    },
                }
            });

            this.slide.mount();
        }
    })

}
